<template>
  <div class="flex overflow-hidden gap-20 select-none">
    <ul class="flex flex-shrink-0 justify-around items-center min-w-full gap-20 animate-marquee">
      <li v-for="partner in partners" :key="partner.src"><img :src="partner.src" :width="partner.width" :height="partner.height" :alt="partner.alt" :class="[partner.class]" aria-hidden="true" loading="lazy"></li>
    </ul>
    <ul aria-hidden="true" class="flex flex-shrink-0 justify-around items-center min-w-full gap-20 animate-marquee">
      <li v-for="partner in partners" :key="partner.src"><img :src="partner.src" :width="partner.width" :height="partner.height" :alt="partner.alt" :class="[partner.class]" aria-hidden="true" loading="lazy"></li>
    </ul>
  </div>
</template>

<script setup lang="ts">
export interface PartnerType {
  src: string
  width: string
  height: string
  alt: string
  class?: string
}

const autoGrowStyle = 'max-w-40 h-auto flex flex-grow'
const partners: PartnerType[] = [
  { src: 'aida', width: "92", height: "30", alt: "AIDA Cruises" },
  { src: 'alltours', width: "92", height: "23", alt: "alltours" },
  { src: 'ameropa', width: "92", height: "23", alt: "Ameropa" },
  { src: 'anex', width: "92", height: "23", alt: "ANEX Tour" },
  { src: 'coral', width: "100", height: "64", alt: "coraltravel" },
  { src: 'costa', width: "92", height: "65", alt: "Costa Kreuzfahrten", class: 'p-4' },
  { src: 'dertour', width: "92", height: "24", alt: "DERTour" },
  { src: 'eti', width: "72", height: "24", alt: "ETI" },
  { src: 'explora', width: "74", height: "24", alt: "Explora Journeys" },
  { src: 'fitreisen', width: "100", height: "36", alt: "fitReisen" },
  { src: 'hurtigruten', width: "127", height: "21", alt: "Hurtigruten Expeditions", class: autoGrowStyle },
  { src: 'msc', width: "92", height: "30", alt: "MSC Cruises" },
  { src: 'ncl', width: "130", height: "32", alt: "Norwegian Cruise Line", class: autoGrowStyle },
  { src: 'olimar', width: "92", height: "30", alt: "OLIMAR", class: 'p-2' },
  { src: 'ponant', width: "142", height: "60", alt: "Ponant", class: '' },
  { src: 'schauinsland', width: "127", height: "31", alt: "Schauinsland Reisen", class: autoGrowStyle },
]
partners.forEach(x => x.src = '/images/partner/' + x.src + '.png')
</script>
